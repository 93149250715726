<template>
  <div class="app-package-list-wrapper">
    <list ref="list"
          :searchUrl="searchUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="新增应用包"
                  permission="add"
                  @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="应用包名称"
                 :width="160"
                 v-model="searchParams.appPackageName"></v-input>
        <v-input label="创建人"
                 placeholder="请输入创建人账号"
                 :width="160"
                 v-model="searchParams.creater"></v-input>
        <v-datepicker label="创建时间"
                      :maxDate="maxDate"
                      type="rangedatetimer"
                      format="YYYY-MM-DD HH:mm:ss"
                      :startTime.sync="searchParams.createTimeStart"
                      :endTime.sync="searchParams.createTimeEnd" />
      </template>
      <template #operateSlot="scope">
        <v-button permission="update"
                  text="编辑"
                  type="text"
                  @click="edit(scope.row)"></v-button>
        <v-button permission="delete"
                  text="删除"
                  type="text"
                  @click="deleteItem(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, delMenuURL } from './api'
export default {
  name: 'applicationPackage',
  components: {
  },
  data () {
    return {
      maxDate: new Date(),
      searchUrl: getListURL,
      searchParams: {
        appPackageName: '',
        creater: '',
        createTimeStart: '',
        createTimeEnd: ''
      },
      headers: [
        {
          prop: 'appIcon',
          label: '图标',
          formatter: (row) => {
            return this.$createElement('img', {
              attrs: {
                src: row.appIcon
              },
              style: {
                width: '40px',
                height: '40px'
              }
            })
          }
        },
        {
          prop: 'appPackageName',
          label: '应用包名称'
        },
        {
          prop: 'contentMenus',
          label: '包含菜单'
        },
        {
          prop: 'creater',
          label: '创建人'
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }
      ]
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    create () {
      this.$router.push({
        name: 'applicationPackageForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'applicationPackageForm',
        query: {
          id: row.id,
          creater: row.creater
        }
      })
    },
    async deleteItem (row) {
      // 操作日志的数据对象参数
      let isOk = await this.$confirm('确认删除？')
      if (isOk) {
        let postData = {
          packageId: row.id,
          dataObject: `${row.appPackageName}-${row.creater}`
        }
        this.$axios({
          method: 'post',
          url: delMenuURL,
          params: postData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          }
        }).then(res => {
          if (res.status === 100) {
            this.$refs.list.searchData()
            this.$message.success('操作成功')
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
