// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}workPackage/list`
// 新增应用包
const addMenuURL = `${API_CONFIG.controlBaseURL}workPackage/add`
// 编辑应用包
const editURL = `${API_CONFIG.controlBaseURL}workPackage/update`
// 获取应用包信息
const getRoleDetailURL = `${API_CONFIG.controlBaseURL}workPackage/detail`
// 获取菜单（新增）
const getViewMenuURL = `${API_CONFIG.controlBaseURL}workPackage/showPackageMenu`
// 删除应用包
const delMenuURL = `${API_CONFIG.controlBaseURL}workPackage/delete`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`
export {
  getListURL,
  addMenuURL,
  editURL,
  getRoleDetailURL,
  getViewMenuURL,
  uploadURL,
  delMenuURL
}
